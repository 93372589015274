import { db } from '@/config/firebase';
import _ from 'lodash';

async function getAssetById(assetCode) {
    let assetObj = {};
    const assetRef = db.collection('assets').doc(assetCode);
    let doc = await assetRef.get();
    if (doc.exists) {
        assetObj = doc.data();
    }
    return assetObj;
}

async function getAssetsByCode(assetCodes) {
    let dbRef = [];

    if (!_.isEmpty(assetCodes)) {
        let assetCodesBy10 = _.chunk(assetCodes, 10);
        for (const groupOf10 of assetCodesBy10) {
            let assetRef = db.collection('assets').where("assetCode", "in", groupOf10).get();
            dbRef.push(assetRef);
        }
    }

    return Promise.all(dbRef).then(results => {
        let assetsObj = {};

        results.forEach(snapshot => {
            snapshot.forEach(doc => {
                if (doc.exists) {
                    let asset = doc.data();
                    if (!_.isEmpty(asset)) {
                        assetsObj[asset.assetCode] = asset;
                    }
                }
            });
        });

        return Promise.all([assetsObj]);
    });
}

async function getAssetsOnCurrCompanyId(currCompanyId) {
    let assetsObj = {};

    const dbRef = db.collection('assets').where("currCompanyId", "==", currCompanyId);

    const querySnapshots = await dbRef.get();
    querySnapshots.forEach(doc => {
        const id = doc.id;
        let asset = doc.data();
        assetsObj[id] = {
            id: id,
            ...asset
        }
    });

    return assetsObj;
}

async function getAssetsOnCurrCompanyIds(currCompanyIds) {
    let dbRef = [];

    if (!_.isEmpty(currCompanyIds)) {
        let companyIdsBy10 = _.chunk(currCompanyIds, 10);
        for (const groupOf10 of companyIdsBy10) {
            let assetRef = db.collection('assets').where("currCompanyId", "in", groupOf10).get();
            dbRef.push(assetRef);
        }
    }

    return Promise.all(dbRef).then(results => {
        let assetsObj = {};

        results.forEach(snapshot => {
            snapshot.forEach(doc => {
                if (doc.exists) {
                    let asset = doc.data();
                    if (!_.isEmpty(asset)) {
                        assetsObj[asset.assetCode] = asset;
                    }
                }
            });
        });

        return Promise.all([assetsObj]);
    });
}

async function getAssetHistories(assetId) {
    let historiesObj = {};

    const dbRef = db.collection('assetHistories')
        .where("assetId", "==", assetId);
    const querySnapshots = await dbRef.get();

    querySnapshots.forEach(doc => {
        const id = doc.id;
        let historyObj = doc.data();
        historiesObj[id] = {
            id: id,
            ...historyObj
        }
    });
    return historiesObj;
}

export default {
    getAssetById,
    getAssetsByCode,
    getAssetsOnCurrCompanyId,
    getAssetsOnCurrCompanyIds,
    getAssetHistories,
}